import React from "react";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../redux/initialStoreState";
import { IInsuranceReports } from "../../../redux/insuranceReports/insuranceReports.types";
import { INoPolicyInsuranceReports } from "./insuranceReport.types";
import { useDispatchWrapper, useRecordCounts } from "../../../hooks";
import { useNavigate } from "react-router-dom";
import { fetchNoPolicyInsuranceReportsListAsync } from "../../../redux/insuranceReports/insuranceReportsActions";
import { CustomSelect } from "../../../components/formsComponents";
import {
  createTabsWithRecordcounts,
  renderDropdownValue,
} from "../../../helpers";
import { Button, MenuItem, Stack } from "@mui/material";
import {
  DataTableV2RowRenderType,
  IDataTableV2Props,
} from "../../../components/TableV2/interfaces/IDataTableV2Props";
import { LoadState } from "../../../constants/enums";
import { StandardTableActions } from "../../../components/StandardTableActions/StandardTableActions";
import { CustomLink } from "../../../components/CustomLink/CustomLink";
import { LeadOppStatus } from "../../../components/LeadOppStatus/LeadOppStatus";
import { CustomChip } from "../../../components/CustomChip";
import {
  clearRecordCounts,
  fetchRecordCountsAsync,
} from "../../../redux/common/commonActions";
import { DataTableV2 } from "../../../components/TableV2/DataTableV2";
import { InsuranceReportDialog } from "./InsuranceReportsDialog/InsuranceReportsDialog";
import { AddOutlined } from "@mui/icons-material";
import { useTableV2State } from "../../../components/TableV2/hooks/useTableV2State";
import moment from "moment";
import { TABLE_CACHING_KEYS } from "../../../constants/constants";

const INITIAL_STATE = {
  // customer_policy_id: 0,
  customer_name: null,
  customer_code: null,
  driver_name: null,
  license_number: null,
  business_reason: "Verify Driver Record",
  date_and_time: moment().format("YYYY-MM-DD HH:mm:ss"),
  days_left: null,
  transaction_type: null,
  report_requested: null,
  request_status: null,
  insurer_code: null,
  intact_branch: null,
  insurer_code_written: null,
  number_of_disclosed_convictions: null,
  policy_number: null,
  policy_status_cancelled: "NA",
  requested_by: null,
  assigned_to: null,
  branch: null,
  producer: null,
  csr: null,
  notes: null,
  file_type: null,
  file_type_two: null,
  file_upload: null,
  file_upload_two: null,
  date_wriitten: null,
  date_destroyed: null,
  date_voided: null,
  date_cancelled: null,
  status: "ACTIVE",
  incremental_number: null,
  years_at_this_address: null,
  current_address_type: null,
  current_address: null,
  current_address_unit_or_suite: null,
  current_address_city: null,
  current_address_state_or_province: null,
  current_address_postal_code: null,
  current_address_country: null,
  new_address_type: null,
  new_address: null,
  new_address_unit_or_suite: null,
  new_address_city: null,
  new_address_state_or_province: null,
  new_address_postal_code: null,
  new_address_country: null,
};

export const NoPolicyInsuranceReportTable: React.FC<{ leadId?: number }> = ({
  leadId,
}) => {
  const { noPolicyList, noPolicyLoading, noPolicyTotalRecords } = useSelector(
    (storeState: IStoreState) => storeState.insuranceReports
  );

  
  const [selectedOpp, setSelectedOppr] = React.useState<{
    isAllSelected: boolean;
    leads: IInsuranceReports[];
  }>({
    isAllSelected: false,
    leads: [],
  });
  const [compliantStatus, setCompliant] = React.useState<
    IInsuranceReports["status"] | "-1"
  >("-1");

  const [openView, setOpenView] =
    React.useState<INoPolicyInsuranceReports | null>(null);

  const dispatch = useDispatchWrapper();

  const navigate = useNavigate();

  const {
    state: { dateState, searchState, tabs, columnsConfig, pagination },
    setDateState,
    setSelectedTab,
    setTableTabs,
    setSearchState,
    setColumnVisibility,
    onPageChange,
    onRowsPerPageChange,
  } = useTableV2State({
    filtersInitialState: {
      defaultDateRange: "last28Days",
      selectedTab: "-1",
    },
    pagination: {
      pageNumber: 1,
      rowsInPerPage: 25,
      apiFetchRowCount: 26,
      extraFetchFactor: 1,
    },
    cachingFilters: {
      uniqueKey: TABLE_CACHING_KEYS.NO_POLICY_INSURANCE_REPORTS,
    },
  });

  const fetchList = async () => {
    dispatch(
      fetchNoPolicyInsuranceReportsListAsync(
        pagination.pageNumber,
        pagination.rowsInPerPage,
        pagination.apiFetchRowCount,
        tabs.selectedTab,
        dateState.dates,
        searchState,
        compliantStatus,
        leadId
      )
    );
  };

  const handleComplete = () => {
    fetchList();
    setOpenView(null);
  };

  React.useEffect(() => {
    fetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pagination,
    dateState.dates,
    tabs.selectedTab,
    searchState,
    compliantStatus,
    leadId,
  ]);

  const menuItems =
    selectedOpp.leads.length > 0 || selectedOpp.isAllSelected
      ? [
          {
            key: "action",
            onRender: () => (
              <CustomSelect
                sx={{ minWidth: "120px" }}
                value={""}
                displayEmpty
                renderValue={renderDropdownValue(`Choose bulk Action`)}
                //onChange={(e) => setBranchOffice(e.target.value as string)}
              >
                <MenuItem value="1">Email</MenuItem>
              </CustomSelect>
            ),
          },
        ]
      : [];

  const insuranceTableProps: IDataTableV2Props = {
    isPagination: true,
    // totalRecords: noPolicyTotalRecords,
    rowsPerPageOptions: pagination.rowsInPerPage,
    extraFetchFactor: pagination.extraFetchFactor,
    isDataLoading: noPolicyLoading === LoadState.InProgress,
    selectionMode: "multiple",
    uniqueRowKeyName: "policy_no_policy_driver_insurance_reports_id",
    tableCommandBarProps: {
      preDefinedPlugins: {
        dateFilter: {
          state: dateState,
          onChange: setDateState,
        },
        search: {
          state: searchState,
          onChange: setSearchState,
        },
        columnVisibility: {
          columnVisibility: columnsConfig.columnVisibility,
          onChange: setColumnVisibility,
        },
        refresh: {
          onClick: fetchList,
        },
      },
      leftItems: {
        customPlugins: [
          ...menuItems,
          // {
          //   key: "status",
          //   onRender: () => (
          //     <CustomSelect
          //       sx={{ minWidth: "120px" }}
          //       value={status}
          //       onChange={(e) => setStatus(e.target.value as "ACTIVE")}
          //     >
          //       <MenuItem value="-1">All Status</MenuItem>
          //       <MenuItem value="Submitted">Submitted</MenuItem>
          //       <MenuItem value="Voided">Voided</MenuItem>
          //       <MenuItem value="Completed">Completed</MenuItem>
          //       <MenuItem value="Cancelled">Cancelled</MenuItem>
          //       <MenuItem value="Destroyed">Destroyed</MenuItem>
          //     </CustomSelect>
          //   ),
          // },
          {
            key: "Compliant",
            onRender: () => (
              <CustomSelect
                sx={{ minWidth: "120px" }}
                value={compliantStatus}
                onChange={(e) => setCompliant(e.target.value as "ACTIVE")}
              >
                <MenuItem value="-1">Both Compliant/Non-Compliant</MenuItem>
                <MenuItem value="ACTIVE">Compliant</MenuItem>
                <MenuItem value="INACTIVE">Non-Compliant</MenuItem>
              </CustomSelect>
            ),
          },

          // {
          //   key: "csvExport",
          //   onRender: (columns, items) => (
          //     <RoleBasedCSVExportPlugin
          //       columns={columns}
          //       items={items}
          //       filePrefixName="Report"
          //       moduleId={MODULE_IDS.INSURANCE_REPORTS}
          //     />
          //   ),
          // },
        ],
      },
      rightItems: {
        customPlugins: [],
      },
    },
    masterColumns: [
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        isFirstColumnSticky: true,
        onRowCellRender: (value, row) => {
          return (
            <StandardTableActions
              onViewClick={() => {}}
              onEditClick={() => {
                navigate(
                  `/insurance-reports/no-policy/${row.license_number}/${row.incremental_number}`
                );
              }}
            />
          );
        },
      },
      {
        key: "customer_policy_id",
        headerName: "#",
        fieldName: "incremental_number",
        renderType: DataTableV2RowRenderType.TEXT_DARK,
      },
      {
        key: "driver_name",
        headerName: "Driver Name",
        fieldName: "driver_name",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: INoPolicyInsuranceReports) => {
          return (
            <CustomLink
              to={`/insurance-reports/no-policy/${row.license_number}/${row.incremental_number}`}
              label={value.value}
            />
          );
        },
      },
      {
        key: "license_number",
        headerName: "License Number",
        fieldName: "license_number",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "business_reason",
        headerName: "Business Reason",
        fieldName: "business_reason",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "insurer_code",
        headerName: "Insurer Code",
        fieldName: "insurer_code",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "branch",
        headerName: "Branch",
        fieldName: "branch",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "producer_code",
        headerName: "Producer",
        fieldName: "producer_code",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "csr_code",
        headerName: "CSR",
        fieldName: "csr_code",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.TEXT,
      },

      {
        key: "request_status",
        headerName: "Request Status",
        fieldName: "request_status",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: INoPolicyInsuranceReports) => {
          return <LeadOppStatus status={row.request_status || ""} />;
        },
      },
      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: INoPolicyInsuranceReports) => {
          if (row.status === "Compliant" || row.status === "ACTIVE") {
            return (
              <CustomChip color="success" size="small" content="Compliant" />
            );
          }
          return (
            <CustomChip color="error" size="small" content="Non-Compliant" />
          );
        },
      },

      {
        key: "transaction_type",
        headerName: "Transaction Type",
        fieldName: "transaction_type",
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "days_left",
        headerName: "Days Left",
        fieldName: "days_left",
        renderType: DataTableV2RowRenderType.CHIP_WARNING,
      },
      {
        key: "insert_ts",
        headerName: "Date Modified",
        fieldName: "insert_ts",
        renderType: DataTableV2RowRenderType.DATE_TIME,
      },
      {
        key: "date_and_time",
        headerName: "Date Created",
        fieldName: "date_and_time",
        renderType: DataTableV2RowRenderType.DATE_TIME,
      },
    ],
    tableTabProps: {
      selectedTab: tabs.selectedTab,
      tabs: tabs.tabs,
      onTabChange: (newSelectedTab) => {
        setSelectedTab(newSelectedTab);
      },
    },
    rows: noPolicyList,

    onPageChange: onPageChange,
    onRowsPerPageChange: onRowsPerPageChange,
    onSelection: (isAllSelected: boolean, rows: IInsuranceReports[]) => {
      setSelectedOppr({ isAllSelected: isAllSelected, leads: rows });
    },
  };

  const { list: recordsCounts, loading: recordCountsLoading } =
    useRecordCounts();

  React.useEffect(() => {
    dispatch(
      fetchRecordCountsAsync(
        "latest_policy_no_policy_driver_insurance_reports",
        "request_status",
        dateState.dates.fromDate,
        dateState.dates.toDate
      )
    );
    return () => {
      dispatch(clearRecordCounts());
    };
  }, [dateState.dates.fromDate, dateState.dates.toDate]);

  React.useEffect(() => {
    const tabsData = createTabsWithRecordcounts(
      [
        { label: "Submitted", value: "Submitted", variant: "warning" },
        { label: "Voided", value: "Voided", variant: "grey" },
        { label: "Completed", value: "Completed", variant: "success" },
        { label: "Cancelled", value: "Cancelled", variant: "error" },
        { label: "Destroyed", value: "Destroyed", variant: "error" },
      ],
      recordsCounts
    );
    setTableTabs(tabsData);
  }, [recordsCounts]);

  return (
    <>
      <Stack direction={"row"} justifyContent={"end"} mb={2}>
        <Button
          variant="contained"
          size="small"
          startIcon={<AddOutlined />}
          onClick={() =>
            setOpenView(INITIAL_STATE as INoPolicyInsuranceReports)
          }
        >
          Order Insurance Reports
        </Button>
      </Stack>
      <DataTableV2 {...insuranceTableProps} />

      {openView && (
        <InsuranceReportDialog
          open={true}
          onClose={() => setOpenView(null)}
          data={openView}
          onComplete={handleComplete}
        />
      )}
    </>
  );
};
